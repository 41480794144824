import React from 'react';
import zxcvbn from 'zxcvbn';
import { getCurrentUser } from '@/stores/getCurrentUser';

type State = {
  strength: number;
  suggestions: string[];
  warning: string;
  newPassword: string;
  verifyPassword: string;
}

type Props = {
  showOldPassword?: boolean;
  oldPasswordId?: string;
  newPasswordId?: string;
  newPasswordVerifyId?: string;
}

const Tooltip = ({ title, children, open }) => {
  return (
    <div
      style={{ position: 'relative', display: 'inline-block' }}
    >
      {children}
      {open && (
        <div
          style={{
            position: 'absolute',
            top: '0',
            left: 'calc(100% + 1.5rem)',
            backgroundColor: 'rgb(248,248,248)',
            minWidth: '25rem',
            fontSize: '1rem',
            padding: '.5rem',
            marginTop: '-.5rem',
            boxShadow: '0 2px 5px rgba(0,0,0,0.15)',
            zIndex: 1000,
          }}
        >
          {title}
        </div>
      )}
    </div>
  );
};
export class ChangePassword extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      strength: 0,
      suggestions: [],
      warning: '',
      newPassword: '',
      verifyPassword: '',
    };
  }

  handleNewPassword = (pwd: string) => {
    const { firstName, lastName, username } = getCurrentUser();
    const forbiddenTerms = ['cdd', 'vault', 'cddvault', firstName, lastName, username];

    const pw = zxcvbn(pwd); // passing in forbiddenTerms seems to have no effect here

    if (forbiddenTerms.some(element => element && pwd.toLowerCase().includes(element.toLowerCase()))) {
      pw.score = Math.min(pw.score, 2);
      pw.feedback.suggestions.push('Do not include your name, username or common terms in your password.');
    }

    this.setState({
      strength: pw.score,
      suggestions: pw.feedback.suggestions,
      warning: pw.feedback.warning,
      newPassword: pwd,
    });
  };

  handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.handleNewPassword(e.target.value);
  };

  handleChangeVerifyPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      verifyPassword: e.target.value,
    });
  };

  componentDidMount(): void {
    this.handleNewPassword('');
  }

  render() {
    const { suggestions, warning, newPassword } = this.state;
    const showOldPassword = this.props.showOldPassword ?? true;

    const oldPasswordId = this.props.oldPasswordId || 'user_update_password_old';
    const oldPasswordName = this.props.oldPasswordId || 'user[update_password][old]';

    const newPasswordId = this.props.newPasswordId || 'user_update_password_new';
    const newPasswordName = this.props.newPasswordId || 'user[update_password][new]';

    const newPasswordVerifyId = this.props.newPasswordVerifyId || 'user_update_password_verify_new';
    const newPasswordVerifyName = this.props.newPasswordVerifyId || 'user[update_password][verify_new]';

    const strength = Math.max(1, this.state.strength);

    const showVerifyError = this.state.newPassword !== this.state.verifyPassword && this.state.newPassword;

    const feedback =
      <span className='ChangePassword-tooltip' data-testid='ChangePassword-tooltip'>
        <div className='strength-meter'>
          <span className='left-label'>Password strength:</span>
          <div className={`bar score${strength}`}>
            <div className='fill'></div>
          </div>
          <span className='right-label'>{['', 'weak', 'weak', 'weak', 'strong'][strength]}</span>
        </div>

        {newPassword
          ? <ul>
            {warning && <li>{warning}</li>}
            {suggestions.map(suggestion => <li key={suggestion}>{suggestion}</li>)}
            {showVerifyError && <li>Passwords do not match</li>}
          </ul>
          : null}
      </span>;

    return <div className="subcontainer spaced floatLeft ChangePassword">
      <table className="simple form update-password">
        <tbody>
          {showOldPassword && <tr data-field="not_used">
            <th>Old password:</th>
            <td>
              <input type="password" name={oldPasswordName} id={oldPasswordId} className="input-text" autoComplete="new-password" />
            </td>
          </tr>}
          <tr data-field="not_used">
            <th>New password:</th>
            <td>
              <Tooltip open={!!newPassword} title={feedback}>
                <input type="password" onChange={this.handleChangePassword}
                  name={newPasswordName} id={newPasswordId} className="input-text" autoComplete="new-password" />
              </Tooltip>
            </td>
          </tr>

          <tr data-field="not_used">
            <th>Verify new password:</th>
            <td>
              <input type="password" onChange={this.handleChangeVerifyPassword}
                className={showVerifyError ? 'input-text error' : 'input-text'}
                name={newPasswordVerifyName} id={newPasswordVerifyId} autoComplete="new-password" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>;
  }
}
